export enum MobileRedirectType {
  NO_ACCOUNT = 'noAccount',
  ACCOUNT_CREATED = 'accountCreated',
  EMAIL_ALREADY_CONFIRMED = 'emailAlreadyConfirmed',
}

export interface MobileRedirectProps {
  hasLogoutButton: boolean;
  title: { id: string; defaultMessage: string };
  subtitle: { id: string; defaultMessage: string };
}

const finishOnboardingMessage = {
  id: 'authentication.signUp.mobileRedirection.configureAccount',
  defaultMessage:
    'Please return to our mobile application to finish the onboarding process and discover our services!',
};

export const noAccountProps: MobileRedirectProps = {
  hasLogoutButton: false,
  title: {
    id: 'authentication.signUp.mobileRedirection.title',
    defaultMessage: 'Please sign up using our mobile app',
  },
  subtitle: {
    id: 'authentication.signUp.mobileRedirection.subtitle',
    defaultMessage: 'It only takes two minutes and you only have to do it once',
  },
};

export const accountCreatedProps: MobileRedirectProps = {
  hasLogoutButton: true,
  title: {
    id: 'authentication.signUp.mobileRedirection.accountCreated',
    defaultMessage: 'We see you have already signed up using our mobile app',
  },
  subtitle: finishOnboardingMessage,
};

export const emailAlreadyConfirmedProps: MobileRedirectProps = {
  hasLogoutButton: true,
  title: {
    id: 'authentication.signUp.mobileRedirection.emailAlreadyConfirmed',
    defaultMessage: 'Your email has already been confirmed',
  },
  subtitle: finishOnboardingMessage,
};

export function getMobileRedirectProps(
  redirectType: MobileRedirectType,
): MobileRedirectProps {
  switch (redirectType) {
    case MobileRedirectType.NO_ACCOUNT:
      return noAccountProps;
    case MobileRedirectType.ACCOUNT_CREATED:
      return accountCreatedProps;
    case MobileRedirectType.EMAIL_ALREADY_CONFIRMED:
      return emailAlreadyConfirmedProps;
    default:
      return noAccountProps;
  }
}
