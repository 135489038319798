'use client';

import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider as ApolloClientProvider } from '@apollo/react-common';
import { ApolloLink } from 'apollo-link';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { createContext, ReactNode } from 'react';
import { RestLink } from 'apollo-link-rest';
import { setContext } from 'apollo-link-context';
import { buildContextSetter } from '@legacy/core/apollo';
import { createCache } from '@app-lib/common/helper/apollo';
const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT ?? 'http://localhost:8000/api';
const graphQLEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ?? 'http://localhost:3000/graphql';
export const ApolloContext = createContext<{
  client?: ApolloClient<NormalizedCacheObject>;
}>({
  client: undefined
});
const ApolloProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const restLink = new RestLink({
    endpoints: {
      api: apiEndpoint
    },
    uri: apiEndpoint,
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  });
  const httpLink = createHttpLink({
    uri: graphQLEndpoint,
    credentials: 'include'
  });
  const authLink = setContext(buildContextSetter());
  const client = new ApolloClient({
    ssrMode: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore mismatched version of apollo: we will have to upgrade apollo client to 3+
    link: ApolloLink.from([authLink, restLink, httpLink]),
    cache: createCache()
  });
  return <ApolloContext.Provider value={{
    client
  }} data-sentry-element="unknown" data-sentry-component="ApolloProvider" data-sentry-source-file="ApolloProvider.tsx">
      <ApolloClientProvider client={client} data-sentry-element="ApolloClientProvider" data-sentry-source-file="ApolloProvider.tsx">{children}</ApolloClientProvider>
    </ApolloContext.Provider>;
};
export default ApolloProvider;