import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { NextPageContext } from 'next';
import cookies from 'next-cookies';
import * as apollo from '../core/apollo';
import config from '../core/config';
import * as sentryHelper from '../core/helper/sentry';
import { shutdown } from '@intercom/messenger-js-sdk';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export async function logoutWithoutRedirect(): Promise<void> {
  if (!config.isBrowser) return;

  const client = apollo.getClient();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (client.cache) {
    await client.cache.reset();
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    await client.mutate({ mutation: LOGOUT_MUTATION });
  } catch (err) {
    sentryHelper.captureException(err as Error);
  }

  shutdown();
}

export async function logout(apolloClient?: ApolloClient<NormalizedCacheObject>): Promise<void> {
  if (!config.isBrowser) return;
  let client = apollo.getClient();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (client?.cache) {
    await client.cache.reset();
  }

  if (apolloClient?.cache) {
    await apolloClient.cache.reset();
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    await client?.mutate({ mutation: LOGOUT_MUTATION });
    await apolloClient?.mutate({ mutation: LOGOUT_MUTATION });
  } catch (err) {
    sentryHelper.captureException(err as Error);
  }
  shutdown();
}

export function isAuthenticated(ctx: NextPageContext): boolean {
  const { sessionid, isloggedin, csrftoken } = cookies(ctx);
  return (!isEmpty(isloggedin) || !isEmpty(sessionid)) && !isEmpty(csrftoken);
}
