import { IncomingHttpHeaders } from 'http';
import { getDataFromTree } from '@apollo/react-ssr';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient, ApolloError } from 'apollo-client';
import { ApolloLink, GraphQLRequest } from 'apollo-link';
import { ContextSetter, setContext } from 'apollo-link-context';
import { ErrorResponse, onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import 'isomorphic-unfetch';
import { AppContextType, AppPropsType } from 'next/dist/shared/lib/utils';
import get from 'lodash/get';
import some from 'lodash/some';
import { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import { Component, ComponentType, ReactElement } from 'react';
import * as apolloHelper from '@app-lib/common/helper/apollo';
import * as errorHelper from '@app-lib/common/helper/error';
import { ErrorCode } from '@app-lib/common/typings';
import * as authenticationService from '../authentication/service';
import config from './config';
import { getCookie } from './helper/browser';
import * as sentryHelper from './helper/sentry';
let apolloClient: ApolloClient<NormalizedCacheObject>;
export const CSRF_TOKEN_HEADER = 'X-CSRFToken';
export function buildContextSetter(incomingHeaders?: IncomingHttpHeaders): ContextSetter {
  return (operation: GraphQLRequest, {
    headers
  }: any): Record<string, string> => {
    if (!incomingHeaders) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...headers,
          // Django checks X-CSRFToken header to prevent csrf attack
          // https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
          [CSRF_TOKEN_HEADER]: getCookie('csrftoken')
        }
      };
    }
    // SSR request context
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...(headers ? headers : incomingHeaders),
        cookie: incomingHeaders.cookie
      }
    };
  };
}
export function errorLinkHandler({
  graphQLErrors,
  networkError
}: ErrorResponse): void {
  if (networkError && (networkError as RestLink.ServerError).statusCode === 403) {
    return;
  }
  errorHelper.handleApolloError({
    networkError,
    graphQLErrors
  } as ApolloError, {
    authenticationErrorHandler: () => {
      return authenticationService.logout();
    }
  });
}
const errorLink = onError(errorLinkHandler);
const restLink = new RestLink({
  endpoints: {
    api: config.apiEndpoint
  },
  uri: config.apiEndpoint,
  credentials: 'include',
  headers: {
    Accept: 'application/json'
  }
});
function createClient(initialState?: object, headers?: IncomingHttpHeaders): ApolloClient<NormalizedCacheObject> {
  const cache = apolloHelper.createCache(initialState);
  const httpLink = createHttpLink({
    uri: config.graphQLEndpoint,
    credentials: 'include'
  });
  const authLink = setContext(buildContextSetter(headers));
  return new ApolloClient({
    cache,
    connectToDevTools: config.isBrowser,
    ssrMode: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore mismatched version of apollo: we will have to upgrade apollo client to 3+
    link: ApolloLink.from([errorLink, authLink, restLink, httpLink])
  });
}
export function getClient(): ApolloClient<NormalizedCacheObject> {
  return apolloClient;
}
export function initApolloClient(initialState?: object, headers?: IncomingHttpHeaders): ApolloClient<NormalizedCacheObject> {
  if (!config.isBrowser) {
    // We forward the original request with credentials cookies on it
    return createClient(initialState, headers);
  }

  // Reuse client on the client-side
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!apolloClient) {
    apolloClient = createClient(initialState);
  }
  return apolloClient;
}
export interface WithApolloClientProps extends AppPropsType {
  apolloClient?: ApolloClient<NormalizedCacheObject>;
}
interface InitialProps {
  apolloState: object;
  headers?: IncomingHttpHeaders;
}
interface Props extends AppProps, InitialProps {}

/**
 * @see https://github.com/zeit/next.js/tree/master/examples/with-apollo
 */
export function withApolloClient(WrappedApp: NextComponentType<AppContextType, AppInitialProps, WithApolloClientProps>): ComponentType {
  return class extends Component<Props> {
    private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
    static displayName = 'withApolloClient(App)';
    constructor(props: Props) {
      super(props);
      this.apolloClient = initApolloClient(props.apolloState);
    }
    static async getInitialProps(ctx: AppContext): Promise<InitialProps> {
      const {
        Component,
        router,
        ctx: {
          req
        }
      } = ctx;
      const apolloClient = initApolloClient(undefined, req?.headers);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const appProps = await WrappedApp.getInitialProps!(ctx);
      if (!config.isBrowser) {
        try {
          await getDataFromTree(<WrappedApp {...appProps} Component={Component} router={router} apolloClient={apolloClient} />);
        } catch (error) {
          // Capturing only non authentication errors
          if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          !some(get(error, 'graphQLErrors', []), {
            code: ErrorCode.AUTHENTICATION_ERROR
          })) {
            sentryHelper.captureException(error as Error, {
              appProps
            });
          }
        }
      }
      const apolloState = apolloClient.cache.extract();
      // Pass down the headers for SSR
      return {
        ...appProps,
        apolloState,
        headers: req?.headers
      };
    }
    render(): ReactElement {
      let apolloClient = this.apolloClient;
      if (!config.isBrowser) {
        // Recreate the client with browser headers
        apolloClient = initApolloClient(this.props.apolloState, this.props.headers);
      }
      return <WrappedApp {...this.props} apolloClient={apolloClient} />;
    }
  };
}