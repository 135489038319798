import { useLazyQuery } from '@apollo/react-hooks';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import gql from 'graphql-tag';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmail } from 'validator';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SignInResult } from '@app-lib/authentication/hooks';
import { SignInFormValues } from '@app-lib/authentication/typings';
import Button from '@legacy-components/Button';
import Feedback, { Level } from '@legacy-components/Feedback';
import { Color } from '@legacy-components/theme';
import Form from '../../../common/components/Form';
import LeftAlign from '../../../common/components/LeftAlign';
import Link from '../../../common/components/Link';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { Routes } from '../../../typings';
import Layout from '../../components/Layout';
import { MobileRedirectType } from '../MobileAppRedirect/helper';
import { handleSignIn, registerIntercomUser } from './helper';
import { isEmpty } from 'lodash';
import { IconButton, InputAdornment } from '@mui/material';
import { FormContainer } from './styles';
import { useRouter } from 'next/navigation';
import { ApolloContext } from '@legacy/core/ApolloProvider';
export interface Props extends Omit<SignInResult, 'loading'> {
  initialValues?: SignInFormValues;
  signInOrSignUpMode?: boolean;
}
interface LoginType {
  type: string;
  url?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LOGIN_TYPE_QUERY = gql`
  query LOGIN_TYPE($email: String) {
    loginType(email: $email)
      @rest(type: "LoginType", path: "/auth/login-type/?email={args.email}") {
      type
      url
    }
  }
`;
function validateSignInForm(values: SignInFormValues, passwordLogin: boolean): FormikErrors<SignInFormValues> {
  const errors: FormikErrors<SignInFormValues> = {};
  if (!isEmail(values.email)) {
    errors.email = 'Invalid email';
  }
  if (passwordLogin && !values.password) {
    errors.password = 'Empty password';
  }
  return errors;
}
const SignIn = ({
  signIn,
  inError,
  invalidCredentials,
  initialValues,
  signInOrSignUpMode = false
}: Props): ReactElement => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const {
    client
  } = useContext(ApolloContext);
  const [getLoginType, loginTypeResult] = useLazyQuery<{
    loginType?: LoginType;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }>(LOGIN_TYPE_QUERY);
  const passwordLogin = loginTypeResult.data?.loginType?.type === 'password';
  useEffect(() => {
    if (loginTypeResult.data?.loginType?.type === 'saml') {
      window.open(loginTypeResult.data.loginType.url, '_self');
    }
  }, [loginTypeResult.data]);
  return <Layout withLogo title={<FormattedMessage id="authentication.signIn.title" defaultMessage="Back in the canteen of the future" />} rightButton={<Button testID="subscribeButton" title={intl.formatMessage({
    id: 'authentication.signIn.subscribeButton',
    defaultMessage: 'Subscribe'
  })} color={Color.QUATERNARY_LIGHT} onPress={() => router.push(signInOrSignUpMode ? `${Routes.SIGNUP}?signInOrSignUpMode=true` : `${Routes.MOBILE_APP_REDIRECT}?mobileRedirectType=${MobileRedirectType.NO_ACCOUNT}`)} small />} data-sentry-element="Layout" data-sentry-component="SignIn" data-sentry-source-file="SignIn.tsx">
      <Formik initialValues={{
      email: '',
      password: '',
      ...initialValues
    }} validate={(values: SignInFormValues): FormikErrors<SignInFormValues> => validateSignInForm(values, passwordLogin)} onSubmit={async ({
      email,
      password
    }: SignInFormValues, formikHelpers: FormikHelpers<SignInFormValues>): Promise<void> => {
      setLoading(true);
      if (!loginTypeResult.data) {
        getLoginType({
          variables: {
            email
          }
        });
      } else {
        const user = await handleSignIn({
          signIn,
          email,
          password
        });
        if (user) {
          router.push(Routes.HOME);
          if (client) {
            void registerIntercomUser(client);
          }
        }
      }
      formikHelpers.setSubmitting(false);
      setLoading(false);
    }} data-sentry-element="Formik" data-sentry-source-file="SignIn.tsx">
        {(formikBag: FormikProps<SignInFormValues>): ReactElement => {
        const submitDisabled = !formikBag.isValid || !formikBag.dirty || formikBag.isSubmitting;
        return <Form onSubmit={formikBag.handleSubmit}>
              {inError && <Feedback level={Level.ERROR}>
                  <FormattedMessage id="authentication.signIn.error.unexpected" defaultMessage="An unexpected error occurred - please try again later. If the problem persists send an email to {supportEmail}." values={{
              supportEmail: <Link color={Color.DANGER} href={`mailto:${intl.formatMessage({
                id: 'common.email.support',
                defaultMessage: 'support@foodles.co'
              })}`}>
                          <FormattedMessage id="common.email.support" defaultMessage="support@foodles.co" />
                        </Link>
            }} />
                </Feedback>}
              {invalidCredentials && <Feedback level={Level.ERROR}>
                  <FormattedMessage id="authentication.signIn.error.invalidCredentials" defaultMessage="Invalid username and/or password" />
                </Feedback>}
              <FormContainer>
                <TextField name="email" label={intl.formatMessage({
              id: 'authentication.signIn.email.label',
              defaultMessage: 'Professional email'
            })} placeholder={intl.formatMessage({
              id: 'authentication.signIn.email.label',
              defaultMessage: 'Professional email'
            })} onChange={formikBag.handleChange('email')} onBlur={formikBag.handleBlur('email')} value={formikBag.values.email} error={formikBag.touched.email && !isEmpty(formikBag.errors.email)} sx={{
              '& .MuiInputBase-input': {
                padding: '16.5px 14px'
              }
            }} fullWidth />
                {passwordLogin && <TextField type={showPassword ? 'text' : 'password'} name="password" label={intl.formatMessage({
              id: 'authentication.signIn.password.label',
              defaultMessage: 'Password'
            })} placeholder={intl.formatMessage({
              id: 'authentication.signIn.password.label',
              defaultMessage: 'Password'
            })} onChange={formikBag.handleChange('password')} onBlur={formikBag.handleBlur('password')} value={formikBag.values.password} error={formikBag.touched.password && !isEmpty(formikBag.errors.password)} fullWidth sx={{
              '& .MuiInputBase-input': {
                padding: '16.5px 14px'
              }
            }} InputProps={{
              endAdornment: <InputAdornment position="end">
                          <IconButton aria-label="Show/Hide password" onClick={() => {
                  setShowPassword(showPassword => !showPassword);
                }} onMouseDown={() => {
                  setShowPassword(showPassword => !showPassword);
                }}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
            }} />}
              </FormContainer>
              <LeftAlign>
                <Link testID="forgottenPasswordLink" onClick={() => router.push(Routes.REQUEST_PASSWORD_RESET)}>
                  <FormattedMessage id="authentication.signIn.forgottenPassword" defaultMessage="Forgot your password?" />
                </Link>
              </LeftAlign>

              <input type="submit" hidden disabled={submitDisabled} />

              {!loading && <Button testID="submitButton" fullWidth onPress={() => void formikBag.submitForm()} accessibilityRole="button" color={Color.PRIMARY} disabled={submitDisabled} title={passwordLogin ? intl.formatMessage({
            id: 'authentication.signIn.submitButton',
            defaultMessage: 'Sign in'
          }) : intl.formatMessage({
            id: 'authentication.signIn.continueButton',
            defaultMessage: 'Continue'
          })} />}
              {loading && <LoadingSpinner />}
            </Form>;
      }}
      </Formik>
    </Layout>;
};
export default SignIn;